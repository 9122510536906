<template>
  <div class="row child-component">
    <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
      <div class="">
        <div class="d-block row col-lg-8 mx-auto">
          <div class="col-12 fieldborder" style="margin-bottom: -22px !important">
            <slot name="label" class="lbltxt">
              <span class="lbltxt" style="margin-left: -2px; text-transform: uppercase">
                Do you need to add or update the Euler grade?
              </span>
              <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9" version="1.1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                v-b-tooltip.hover.v-dark.right.html="
                  ' This is a required Omnicom Credit Rating used for all new Client Setups (new Business wins for OMG). <br /> <br /> If this is an existing client of the agency, please check with your Finance Director and Working Capital Director to determine if the Euler score needs to be updated and to ensure appropriate insurance coverage.'
                ">
                <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path
                    d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                    id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                </g>
              </svg>
            </slot>

            <b-form-group class="timeonly-text omniipclass">
              <b-form-radio-group id="time-only" v-model="Eular.TimeOnly" name="time-only" @change="EularRadioChange">
                <b-form-radio value="true">Yes</b-form-radio>
                <b-form-radio value="false">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>

          <div v-show="Eular.TimeOnly == 'true'">
            <div class="col-12 fieldborder">
              <slot name="label" class="lbltxt">
                <span class="lbltxt" style="margin-left: -2px; text-transform: uppercase">
                  IS THE MEDIA SPEND EXPECTED TO EXCEED THE EULER INSURANCE
                  COVERAGE?
                </span>
                <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  v-b-tooltip="options">
                  <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path
                      d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                      id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                  </g>
                </svg>
              </slot>
              <b-form-group label="" class="lbltxt omniipclass" style="margin-left: -2px">
                <b-form-radio-group id="euler-insure" v-model="Eular.Insurance" name="euler-insure"
                  @change="returnfileCount">
                  <b-form-radio value="true">Yes</b-form-radio>
                  <b-form-radio value="false">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="fieldborder" style="">

              <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Euler Code" name="Euler Grade"
                :showLabel="true" label="DDS MEDIA OFFICE CODE" v-model="Eular.EularGradeVal" :options="eulargradelevel"
                @change="EulerGradeChange">
              </OmniSelect>
            </div>

            <div style="padding-top: 10px !important" class="fieldborder"
              v-if="Eular.Insurance == 'true' || Eular.EularGradeVal">
              <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Approval Source"
                name="APPROVAL SOURCE" :showLabel="true" label="APPROVAL SOURCE" v-model="Eular.ApprovalSourceLevel"
                :options="ApprovalList"></OmniSelect>
            </div>

            <div class="fieldborder" style="margin-top: -17px !important">  
              <!-- <label for="requestclientcode" class="lbltxt" toolTipText=""
              >REQUESTED CLIENT CODE</label> -->
              <slot name="label" class="lbltxt">
                <span class="lbltxt" style="margin-left: 11px">
                  CLIENT ENTITY NAME PER AGREEMENT
                </span>
                <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  v-b-tooltip.hover.v-dark.right.html="
                    '“Who is Omnicom Contracting with?”.'
                  ">
                  <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path
                      d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                      id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                  </g>
                </svg>
              </slot>
              <b-form-input autocomplete="off" id="entitynameperagreement" class="omniipclass"
                v-model="Eular.ClientEntityName" placeholder="Enter Client Name Per Agreement"
                :maxlength="30"></b-form-input>
            </div>
            <div class="fieldborder"  style="margin-top: -15px !important">
              <span class="lbltxt" style="margin-left: 11px">
                Comments
                </span>
              <b-form-textarea id="textarea" placeholder="Enter Comments" v-model="Eular.Comments"
                :maxlength="200" rows="8" max-rows="8"></b-form-textarea>
            </div>
            <!-- <div class="fieldborder" style="margin-top: -17px !important">
              <label for="entityname" class=""
                >CLIENT ENTITY NAME PER AGREEMENT</label
              >
              <b-form-input
                autocomplete="off"
                class="omniipclass"
                id="entitynameperagreement"
                v-model="Eular.ClientEntityName"
                placeholder="Enter Client Name Per Agreement"
              ></b-form-input>
            </div> -->
            <div class="fieldborder" style="margin-top: 15px !important">
              <vue-dropzone v-on:vdropzone-mounted="mountedmyVueDropzone" :duplicateCheck="true" ref="myVueDropzone"
                id="dropzone" :useCustomSlot="true" class="customdropzone" :include-styling="false"
                :options="dropzoneOptions" v-on:vdropzone-file-added="UploadedToDropzone"
                v-on:vdropzone-removed-file="RemovedFromDropzone">
                <div class="dropzone-custom-content">
                  <p class="Drag-and-drop-file-h">
                    <b-img :src="closeFilterIcon" style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      " />
                    Drag and drop file here or
                    <span class="draganddropspan"> browse </span>
                  </p>
                </div>
              </vue-dropzone>
            </div>
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
    <div class="form-group pt-30 text-right footer-buttons">
      <button class="btn btn-link text-uppercase" @click="Cancel()">
        CANCEL
      </button>

      <button class="text-uppercase btn btn-secondary btn-primary" @click="SaveasDraft()">
        SAVE FOR LATER
      </button>

      <button class="text-uppercase btn custom-button btn-primary" @click="NavigateToclientInfo()">
        BACK
      </button>
      <button class="text-uppercase btn custom-button btn-primary" :disabled="$v.$invalid && Eular.TimeOnly == 'true'"
        @click="AddClientEularInfo()">
        NEXT
      </button>
    </div>
    <br />
    <!-- </div> -->
  </div>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";

import store from "@/store";
import { mapGetters } from "vuex";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import service from "../../services/Dropdowns";
export default {
  isdraftrequest: false,
  validations: {
    Eular: {
      TimeOnly: {
        required,
      },
      ClientEntityName: { required },
      EularGradeVal: { required },
      Insurance: { required },
      ApprovalSourceLevel: {
        required: requiredIf(function (model) {
          return model.EularGradeVal > 7 || model.Insurance == "true";
        }),
      },
      EulerFiles: {
        required: requiredIf(function (model) {
          return model.TimeOnly == "true" && (model.Insurance == "true" || model.EularGradeVal > 7);
        })
      }
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    isDraft: {
      type: Boolean,
      required: false,
    },
  },
  beforeDestroy() {
    this.isdestroying = true;
  },

  mounted() {
    this.initiateStep();
    if (this.IsEdit == true && this.clientinfopageone.IsEditDataLoaded == true) {
      this.ManuallyUploadFiles(this.Eular.EulerFiles);
    }
  },
  activated() {
    this.initiateStep();
  },
  created() {
    this.initiateStep();
    this.InitiateObject();
  },

  computed: {
    ...mapGetters("client", {
      Eular: "ClientEularInfo",
    }),

    ...mapGetters("auth", {
      myaccessToken: "accessToken",
    }),

    ...mapGetters("client", {
      clientinfopagetwo: "ClientEularInfo",
    }),

    ...mapGetters("client", {
      clientinfopageone: "ClientBasicDetails",
    }),
    IsEdit() {
      return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
    },
  },
  data() {
    let filecount = 0;

    return {
      RemoveFileList: [],
      ClientDraftRequestId: 0,
      clientForm: {},
      options: {
        title:
          "<h6 style='color:white !important;font-family:Open Sans'> Network CFO and Omnicom approve as follows: </h6> <span style='font-family:Open Sans'> •	Terms > 60 day  </span> </br> <span style='font-family:Open Sans'> •	Grades 1-4: > $3MM (Uninsured Exposure) </span>  </br> <span style='font-family:Open Sans'> •	Grades 5-7: > $1MM (Uninsured Exposure) </span> </br> <span style='font-family:Open Sans'> •	Grades 8-10: All </span> ",
        html: true,
        placement: "right",
      },
      TooltipInfoIcon: require("@/assets/Images/infoicon.svg"),
      dropzonefileuploaded: null,
      hasuploadfile: false,

      deleteIcon: require("@/assets/Icons/delete.svg"),
      closeFilterIcon: require("../../assets/Icons/file.png"),
      FilesUploaded: false,
      dropzoneOptions: {
        paramName1: filecount,
        paramName: "file",
        //params: {'param_1':'xyz','para_2': localid },
        previewTemplate: this.template(),
        url: `${process.env.VUE_APP_API_ROOT}api/v1/mdm/Upload`,
        uploadMultiple: true,
        maxFiles: 5,
        minFiles: 1,
        maxFilesize: 10,
        addRemoveLinks: false,
        parallelUploads: 5,
        createImageThumbnails: false,
        autoProcessQueue: false,
        //  headers:{"Authorization":'Bearer eyJraWQiOiJRS2V5YUxEY2RuQm9YKzlibm4rTGJQbjQrMVhiMjFuR2hHQ0l2aHpPQmJrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoicldwdUNRbV9PenBvZjhwUG1iOFg4QSIsInN1YiI6IjgzNWU1NGQxLWY4YzMtNDJiYy1hZGQ2LTI4Y2RmZjdiNTc4ZiIsImNvZ25pdG86Z3JvdXBzIjpbImV1LXdlc3QtMV9yemluaUtwNzJfT01HLUFERlMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfcnppbmlLcDcyIiwiY29nbml0bzp1c2VybmFtZSI6Ik9NRy1BREZTX2NoYW5kcnUucG9ubnVzYW15QGFubmFsZWN0LmNvbSIsImdpdmVuX25hbWUiOiJDaGFuZHJ1IiwiYXVkIjoiNzM3MGlzOXZnb2Ewb2g2OXR2MHY2dDAxdXUiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJjaGFuZHJ1LnBvbm51c2FteUBhbm5hbGVjdC5jb20iLCJwcm92aWRlck5hbWUiOiJPTUctQURGUyIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwOlwvXC9vbWdzc28ub25lb21nLmNvbVwvYWRmc1wvc2VydmljZXNcL3RydXN0IiwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDE5OTU4MzIxMTEifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA3MzM4MjM0LCJleHAiOjE2MDkzMDg5MzUsImlhdCI6MTYwOTMwNTMzNSwiZmFtaWx5X25hbWUiOiJQb25udXNhbXkifQ.A66H0Vuv-pxNRKKOvG6BXsu4bK-Q0NfI5ORK8A8F8Vc59-RwHnuGc4tOL9TZT215YGA8kAh3o4WVs0YMFwXX5hmt7jKOCKKQyCjDhoLMPKNDVZ5eWULEoXVqHky5ZewvVc5y3Evo4rLsfHnyT1OC4j24oc8lwFFOshnqGGzkVNQ8zE4z39MdlioC9zQVxSzaxbAbnyol5qHQWQMXFhLHZOlrQ_5qxYirGdeZd0W7gdY6W8Asqj6thQ37tLf9537B6H54UKPxVeQYzxazbgsWqOFYs8jcMVTxI8dEIK25V-LgPf5Lba3BhqqaVGU_kVcPHH0qzmtRr38tfMIsZi-2Bg'},
        // headers: { "My-Awesome-Header": "header value", GUID: localStorage.getItem("UploadfileId") },
        init: function () {
          // this.on("maxfilesexceeded", function (file) {
          //   this.removeFile(file);
          //   alert("Maximum File Upload limit is 5!");
          // });

          this.on("addedfile", function (file) {
            if (this.files.length > 5) {
              this.removeFile(file);
              window.alert("Maximum File Upload limit is 5!");
            }
          });

          this.on("removedfile", function () {
            //       //this.Removefromserver(file.name);
            //       //alert(file.name);
            //        let filenamArray=[];
            // var serverFilles=this.clientinfopagetwo.Files;
            // alert(JSON.stringify(filenamArray));
            // for(var i=0; i <serverFilles.length; i++)
            // {
            //     filenamArray.push(serverFilles[i].upload.filename);
            // }
            // if(filenamArray.includes('Hololens'))
            // {
            //    alert('call server');
            // }
            // else
            // {
            //   alert('remove from client');
            // }
          });
        },
      },
      myFiles: [],
      ApprovalList: [
        { label: "Network CFO ", value: "2" },
        { label: "Omnicom CFO", value: "3" },
      ],
      eulargradelevel: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
      ],
    };
  },
  // deactivated() {
  //   if (this.$refs.myVueDropzone.dropzone.files.length > 0) {
  //     store.commit(
  //       "client/addEularFiles",
  //       this.$refs.myVueDropzone.dropzone.getAcceptedFiles(),
  //       this.$refs.myVueDropzone.dropzone
  //     );
  //     this.$refs.myVueDropzone.dropzone.processQueue();
  //   }
  // },

  watch: {
      "clientinfopageone.IsEditDataLoaded": function (val) {
          if (this.IsEdit == true && val == true) {
              this.ManuallyUploadFiles(this.Eular.EulerFiles);
          }
      },
    },
  methods: {
    // RemovedFromDropzone(file) {
    //   var testundefined = false;
    //   if (this.id == undefined) {
    //     testundefined = true;
    //   } else {
    //     testundefined = false;
    //   }

    //   window.console.log(JSON.stringify(this.id == undefined));
    //   window.console.log(
    //     JSON.stringify(localStorage.getItem("UploadfileId") != "")
    //   );

    //   window.console.log(testundefined);

    //   if (this.isdestroying != true) {
    //     this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
    //     if (
    //       testundefined == false ||
    //       localStorage.getItem("UploadfileId") != ""
    //     ) {
    //       this.RemoveFileList.push(file.name);
    //     }
    //   }
    //   window.console.log(JSON.stringify(this.RemoveFileList));
    // },

    mountedmyVueDropzone() {
        if (this.IsEdit == true && this.FilesUploaded == false) {
            this.ManuallyUploadFiles(this.Eular.EulerFiles);
            this.$set(this.Eular, "EulerFiles", this.GetFilesObject());
        }
    },
    UploadedToDropzone() {
            this.$set(this.Eular, "EulerFiles", this.GetFilesObject());
        },
    RemovedFromDropzone() {
            this.$set(this.Eular, "EulerFiles", this.GetFilesObject());
        },
    GetFilesObject() {
            let files = [];
            if (this.$refs.myVueDropzone.dropzone.files && this.$refs.myVueDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.myVueDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,file:filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },

    SendingMultiple() {
      this.ShowSpinner();
    },
    ShowSpinner() {
      store.commit("progress/setProcessing", {
        root: true,
      });
    },
    StopSpinner() {
      store.commit("progress/removeProcessing", {
        root: true,
      });
    },
    IsFileUploaded(file) {
      let files = this.FilesUploaded;
      if (files != null && files.length > 0) {
        let index = files.findIndex(function (fileUploades) {
          return fileUploades == file.upload.filename;
        });
        return index > -1;
      } else {
        return false;
      }
    },
    DeleteUploadedFile(file) {
      if (this.IsFileUploaded(file)) {
        var guid = localStorage.getItem("UploadfileId");
        let fileDetails = {
          Name: file.name,
          GUID: guid,
          ID:
            this.id != null && parseInt(this.id) > 0 ? parseInt(this.id) : null,
        };
        if (
          (fileDetails.GUID != null &&
            fileDetails.GUID != "" &&
            fileDetails.length > 0) ||
          (fileDetails.ID != null && fileDetails.ID > 0)
        ) {
          this.$store
            .dispatch("client/DeleteUploadedFile", fileDetails)
            .then(() => {
              store.commit(
                "client/addEularFiles",
                this.$refs.myVueDropzone.dropzone.files
              );
              let indexToDelete = this.FilesUploaded.findIndex(function (
                fileDelete
              ) {
                return fileDelete == file.upload.filename;
              });
              if (indexToDelete > -1) {
                this.FilesUploaded.splice(indexToDelete, 1);
              }
              // store.dispatch(
              //   "progress/setSuccessMessage",
              //   "File deleted successfully."
              // );
            })
            .catch(() => {
              store.dispatch(
                "progress/setErrorMessage",
                "Error while deleteing uploaded file(s)"
              );
            });
        }
      }
    },
    ManuallyUploadFiles(files) {
      if (this.$refs.myVueDropzone != null) {
        if (files != null && files.length > 0 && this.FilesUploaded == false) {
          files.forEach((fileUploaded) => {
            let file = {
              size: fileUploaded.Length,
              name: fileUploaded.Name,
              type: fileUploaded.Extension,
            };
            var url = fileUploaded.FullName;
            this.$refs.myVueDropzone.manuallyAddFile(file, url);
          });
        }
        this.FilesUploaded = true;
      }
    },
    LoadFiles() {
      this.$store
        .dispatch("client/RenderUploadedFiles", parseInt(this.id))
        .then((res) => {
          if (res) {
            this.ManuallyUploadFiles(res.data);
          }
        })
        .catch(() => {
          store.dispatch(
            "progress/setErrorMessage",
            "Error while loading uploaded files"
          );
        });
    },
    LoadDraftFiles() {
      this.$store
        .dispatch("client/RenderDraftUploadedFiles", parseInt(this.id))
        .then((res) => {
          if (res) {
            this.ManuallyUploadFiles(res.data);
          }
        })
        .catch(() => {
          store.dispatch(
            "progress/setErrorMessage",
            "Error while loading uploaded files"
          );
        });
    },
    initiateStep() {
      this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    template: function () {
      return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
    },

    InitiateObject() {},
    addAnalysisId(result) {
      var a = 1;
      if (a == 1) {
        if (result.xhr.status == 200) {
          window.localStorage.setItem("UploadfileId", result.xhr.response);
          if (this.isdraftrequest) {
            service
              .UpdateICRequestGUID(
                this.ClientDraftRequestId,
                window.localStorage.getItem("UploadfileId")
              )
              .then((res) => {
                window.console.log(JSON.stringify(res));
                this.StopSpinner();
                store.dispatch(
                  "progress/setSuccessMessage",
                  `The Client request have been saved as Draft successfully`
                );
                window.localStorage.setItem("UploadfileId", "");
                this.$router.push("/ClientProduct");
              })
              .catch((ex) => {
                window.console.log(JSON.stringify(ex));
                this.$store.dispatch("progress/setErrorMessage", `${ex}`);
                window.localStorage.setItem("UploadfileId", "");
              });
          }
        }
        a = a + 1;
      }
    },

    constructurl(isdraftrequest) {
      if (isdraftrequest) {
        this.$refs.myVueDropzone.setOption(
          "url",
          `${process.env.VUE_APP_API_ROOT}api/v1/Clients/ClientDraftUpload`
        );
      } else {
        this.$refs.myVueDropzone.setOption(
          "url",
          `${process.env.VUE_APP_API_ROOT}api/v1/mdm/Upload`
        );
      }
    },

    Uploaderror(result) {
      window.console.log("Error uploading1" + JSON.stringify(result));
      window.localStorage.setItem("UploadfileId", "");
    },

    sendingEvent(file, xhr, formData) {
      formData.append("fileuploadId", localStorage.getItem("UploadfileId"));
      formData.append("removefilenames", this.RemoveFileList);
      formData.append("DraftClientRequestId", this.ClientDraftRequestId);
    },

    Upload() {
      //let dropzone = this.$refs.myVueDropzone;
      //dropzone.processQueue();
      store.commit(
        "client/addEularFiles",
        this.$refs.myVueDropzone.dropzone.files
      );
    },

    returnfileCount() {
      this.Eular.EularGradeVal=null;
      this.Eular.ApprovalSourceLevel=null;
    },

    EulerGradeChange(){
      this.Eular.ApprovalSourceLevel=null;
    },

    EularRadioChange() {},
    NavigateToRoute(name) {
      if (this.id) {
        this.$router.push({
          name: `${name}`,
          params: { id: this.id, isDraft: this.isDraft },
        });
      } else {
        this.$router.push({ name: name });
      }
    },
    AddClientEularInfo() {
      this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
      store.commit("client/NewClientEularInfo", this.Eular);
      if (this.$refs.myVueDropzone.dropzone.files.length > 0) {
        this.isdraftrequest = false;
        store.commit(
          "client/addEularFiles",
          this.$refs.myVueDropzone.dropzone.files
        );
        //if i am not adding any new files but deleting the existing one trigger delete methode
        this.$refs.myVueDropzone.dropzone.processQueue();
      }

      // if (this.RemoveFileList.length > 0) {
      //   var inputObj = {
      //     name: this.RemoveFileList,
      //     requestid: this.isDraft==true?'NaN':parseInt(this.id),
      //     guidId: localStorage.getItem("UploadfileId"),
      //   };

      //   window.console.log(JSON.stringify(inputObj));

      //   service.RemoveClientTempDirectory(inputObj);
      // }

      store.commit(
        "client/addEularFiles",
        this.$refs.myVueDropzone.dropzone.files
      );

      this.NavigateToRoute("Billing");
      this.RemoveFileList = [];
      this.InitiateObject();
    },

    SaveasDraft() {
      this.$store
        .dispatch("client/SaveClientDraft", this.isDraft==true?parseInt(this.id):null)
        .then((res) => {
          // window.console.log(JSON.stringify(res));
          if (res.status == 200) {
            // this.ShowSpinner();

            // if (this.$refs.myVueDropzone.dropzone.files.length > 0) {
            //   this.isdraftrequest = true;
            //   this.constructurl(this.isdraftrequest);
            //   this.ClientDraftRequestId = res.data.message;
            //   this.$refs.myVueDropzone.dropzone.processQueue();
            // } else {
            //   this.StopSpinner();
            //   store.dispatch(
            //     "progress/setSuccessMessage",
            //     `The Client request have been saved as Draft successfully`
            //   );
            // }
            this.$router.push("/ClientProduct");
          } else {
            window.console.log("Error while Saving Client as Draft");
          }
        })
        .catch((ex) => {
          this.$store.dispatch(
            "progress/setErrorMessage",
            `Error while Saving Client as Draft. ${ex}`
          );
        });
    },

    NavigateToclientInfo() {
      // if (this.$refs.myVueDropzone.dropzone.files.length > 0) {
      //   store.commit(
      //     "client/addEularFiles",
      //     this.$refs.myVueDropzone.dropzone.files
      //   );
      //   this.isdraftrequest = false;
      //   this.constructurl(false);
      //   //if i am not adding any new files but deleting the existing one trigger delete methode
      //   this.$refs.myVueDropzone.dropzone.processQueue();
      // }

      // if (this.RemoveFileList.length > 0) {
      //   var inputObj = {
      //     name: this.RemoveFileList,
      //     requestid: this.isDraft==true?'NaN':parseInt(this.id),
      //     guidId: localStorage.getItem("UploadfileId"),
      //   };

      //   window.console.log(JSON.stringify(inputObj));

      //   service.RemoveClientTempDirectory(inputObj);
      // }

      store.commit(
        "client/addEularFiles",
        this.$refs.myVueDropzone.dropzone.files
      );

      //this.RemoveFileList=[];
      this.NavigateToRoute("Client Info");
    },

    Cancel() {
      this.RemoveFileLis = [];
      this.$router.push("/ClientProduct");
    },
  },
};
</script>



<style scoped>
.tooltip-inner {
  background-color: white !important;
  color: white !important;
  max-width: 500px !important;
}

.tooltip {
  max-width: 500px !important;
}

.tooltip-inner {
  background-color: #2c3865 !important;
}

.dz-max-files-reached {
  background-color: red;
}

.customdropzone > .dz-preview dz-file-preview {
  border-top: dashed 2px #e9eef0 !important;
}

.iconDetails {
  margin-left: -28%;
  float: left;
  height: 14px;
  width: 40px;
}
.FileforNewClientxls {
  width: 149px !important;
  height: 10px !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: #00a1d2 !important;
}

.dz-image img {
  display: none !important;
}

.hoverclass {
  border-radius: 87px !important;
  width: 14px !important;
  height: 15px !important;
}

.floating-label-input {
  margin-top: 0px !important;
}

.fieldborder {
  padding-bottom: 15px !important;
}

.customdropzone {
  height: 44px !important;
  border-radius: 8px !important;
  border: dashed 2px #e9eef0 !important;
  background-color: #ffffff !important;
  cursor: pointer !important;
}

.Drag-and-drop-file-h {
  margin-left: 22px !important;
  margin-top: 14px !important;
  width: 237px !important;
  height: 10px !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: #a2a9ad !important;
}

.draganddropspan {
  color: #00a1d2;
}

.omniipclass {
  height: 35px !important;
  border: #e9eef0 !important;
  /* style="width:415px; height:35px;" */
}

.filepond--drop-label.filepond--drop-label label {
  text-transform: inherit !important;
  color: darkgray !important;
  cursor: default !important;
  font-size: 0.875em !important;
  font-weight: 400 !important;
  text-align: center !important;
  line-height: 1.5 !important;
}

.Text {
  width: 250px;
  height: 196px;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.Text .text-style-1 {
  font-weight: 600;
}
.verticlaline {
  width: 740px;
  height: 0px;
  border: solid 1px #e2e2e2;
  margin-left: 106px;
}
.Radio-Unselected {
  width: 14px;
  height: 14px;
  object-fit: contain;
  background-color: #4d5161;
}
.lbltxt {
  /* width: 300px; */
  height: 8px;
  font-family: "Open Sans";
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  color: #4d5161;
}
.AdditionalText {
  height: 20px;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4d5161;
}
</style>